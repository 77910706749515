const getStatus = (product, defaultStatus) => {
  if (product.stock) {
    if (product.stock.max_sale_qty === 0) return 'out_of_stock'
    return product.stock.is_in_stock ? 'ok' : 'out_of_stock'
  }

  return defaultStatus
}

function isInStock (product) {
  return getStatus(product, 'out_of_stock') === 'ok'
}

export {
  isInStock,
  getStatus
}
