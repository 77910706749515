import { Logger } from '@vue-storefront/core/lib/logger'

import Product from '@vue-storefront/core/modules/catalog/types/Product';
import { ProductOption } from '@vue-storefront/core/modules/catalog/types/ProductConfiguration';
export default function getConfigurationByChildProduct (
  parentProduct: Product,
  childProduct: Product,
  availableOptions: Record<string, ProductOption[]>,
  currentProductOptions: Record<string, ProductOption[]>
): Record<string, ProductOption> {
  const optionsNames = Object.keys(availableOptions)
  const childProductConfigurationEntries =
    Object.entries(childProduct).filter(([attributeName]) => optionsNames.includes(attributeName))
  const configuration = childProductConfigurationEntries.map(([configurableOptionCode, optionId]) => {
    const optionOnCurrentProduct = currentProductOptions[configurableOptionCode]
      .find(option => option.id === `${optionId}`)
    return [ configurableOptionCode, optionOnCurrentProduct ]
  })
  const areSomeOptionsNonExisting = Object.values(configuration).some(option => !option)
  if (areSomeOptionsNonExisting) {
    Logger.error(`Unable to set configuration ${JSON.stringify(configuration)} for product of sku "${parentProduct.parentSku}"`)
    return null
  }
  return (Object as any).fromEntries(configuration)
}
